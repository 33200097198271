import { ZoneStatus } from '../supplierZones/zoneStatus';
import { ISupplierContact } from './ISupplierContact';

export class SupplierContact implements ISupplierContact {
	public Id?: number;
	public SupplierId?: number;
	public ParentId?: number;
	public FirstName = '';
	public LastName = '';
	public Telephone = '';
	public Mobile = '';
	public Fax = '';
	public Email = '';

	public Status = ZoneStatus.PendingReview;
}
