
























































































































































































































































































































































































































































































































































import { Supplier } from '@/classes/suppliers/supplier';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

// Services
import { HttpService } from '@/services/generic/httpService';

// Modules
import { SuppliersModule } from '@/store/suppliers/suppliers';
import { ZoneStatus } from '@/classes/supplierZones/zoneStatus';
import { AuthStore } from '@/store/auth/auth';
import { SupplierContact } from '@/classes/suppliers/supplierContact';
import { ISupplierContact } from '@/classes/suppliers/ISupplierContact';

@Component({
	components: {},
})
export default class Vitals extends Vue {
	httpService = new HttpService();
	authModule = AuthStore;
	zoneStatus = ZoneStatus;

	date = '';
	liabilityExpiryMenu = false;
	wasteCarrierExpiryMenu = false;

	isEditContact = false;
	@Watch('isEditContact')
	onSearch(val: boolean) {
		if (val) {
			(document.getElementsByClassName(
				'v-card'
			)[1] as HTMLElement).style.overflowY = 'scroll';
		} else {
			(document.getElementsByClassName(
				'v-card'
			)[1] as HTMLElement).style.overflowY = 'hidden';
		}
	}

	isFormValid = false;
	vitals = new Supplier();
	vitalsForm: any;
	supplierContact = new SupplierContact();

	subscription: any;
	showRejectPopup = false;
	vitalsStatus = ZoneStatus;

	beforeDestroy() {
		this.subscription();
	}

	mounted() {
		this.vitalsForm = this.$refs.vitalsForm;

		this.subscription = this.$store.subscribe(mutation => {
			if (mutation.type.startsWith('SelectSupplier')) {
				this.GetData();
			}
		});
	}

	created() {
		this.GetData();
	}

	GetData() {
		this.httpService
			.Get<Supplier>(
				`Vitals?supplierId=${SuppliersModule.GetSelectedSupplier.SupplierId}`
			)
			.then(data => {
				this.vitals = data;
				this.isEditContact = false;
				this.supplierContact = new SupplierContact();
				this.vitalsForm.validate();
			});
	}

	DeleteContact(contact: ISupplierContact) {
		if (contact.Id && contact.Id < 0) {
			const index = this.vitals.Contacts.findIndex(xx => xx.Id === contact.Id);

			if (index !== -1) {
				this.vitals.Contacts.splice(index, 1);
			}

			return;
		}

		if (contact.Status === ZoneStatus.PendingDelete) {
			contact.Status = ZoneStatus.PendingReview;
		} else {
			contact.Status = ZoneStatus.PendingDelete;
		}
	}

	ReviewContact(contact: ISupplierContact) {
		this.isEditContact = true;
		this.supplierContact = contact;
		setTimeout(() => {
			document.getElementsByClassName('mdi-web')[0].scrollIntoView();
		}, 0);
	}

	EditContact(contact: ISupplierContact) {
		this.isEditContact = true;
		this.supplierContact = { ...contact, Status: ZoneStatus.PendingReview };
		setTimeout(() => {
			this.vitalsForm.validate();
			document.getElementsByClassName('mdi-web')[0].scrollIntoView();
		}, 0);
	}

	AddContact() {
		this.isEditContact = true;
		this.supplierContact = new SupplierContact();

		setTimeout(() => {
			document.getElementsByClassName('mdi-web')[0].scrollIntoView();
		}, 0);
	}

	InsertContact() {
		const index = this.vitals.Contacts.findIndex(
			xx => xx.Id === this.supplierContact.Id
		);

		if (index !== -1) {
			this.vitals.Contacts[index] = this.supplierContact;
		} else {
			this.vitals.Contacts.push({
				...this.supplierContact,
				Id: -Math.abs(this.vitals.Contacts.length),
			});
		}

		this.isEditContact = false;
		this.supplierContact = new SupplierContact();
	}

	Save() {
		this.httpService
			.Put<Supplier, SupplierContact[]>(
				`VitalsReview?supplierId=${SuppliersModule.GetSelectedSupplier.SupplierId}`,
				this.vitals
			)
			.then(data => {
				this.vitals.ParentId = SuppliersModule.GetSelectedSupplier.SupplierId;
				this.vitals.Contacts = data;
			});
	}

	ActionDocument(confirmed: boolean) {
		this.vitals.PortalStatus = confirmed ? null : ZoneStatus.ReviewRejected;

		this.showRejectPopup = false;

		this.httpService.Get(
			`${
				confirmed ? 'VitalsReview/ConfirmReview' : 'VitalsReview/ReviewRejected'
			}?supplierId=${SuppliersModule.GetSelectedSupplier.SupplierId}${
				confirmed ? '' : '&rejectionReason=' + this.vitals?.RejectionReason
			}`
		);
	}
}
